import wx from 'weixin-js-sdk'
import {getWXPara} from "./api";

export default function getWXSign () {
  return new Promise((resolve, reject) => {
    let urlStr = window.location.href
    if (urlStr.indexOf('?from') > -1) {
      urlStr = urlStr.split('?from')[0]
    }
    console.log('截取url：', urlStr)

    getWXPara({
      shareUrl: urlStr
    }).then(res => {
      if (res.code === 200 && res.data.businessCode === 200){
        wx.config({debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['onMenuShareAppMessage',  'updateTimelineShareData', 'updateAppMessageShareData']
        })
        wx.ready(function () {
          resolve()
        })
        wx.error(function (error) {
          console.log('wxerror：', error)
        })

      }else {
        console.log("分享接口出错1")
      }

    }).catch(()=>{
      console.log("分享接口出错")
    })
  })
}
