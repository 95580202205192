<template>
  <div class="qbg">
    <img :src="bgTop" class="bg-top">
    <img :src="ckhw" class="ckhw-img">
    <div class="cloud-wrap flex">
      <img :src="cloud" class="cloud-img">
      <span>活动回顾：助农直播·家乡好物</span>
      <img :src="cloud" class="cloud-img cloud-r">
    </div>
    <div class="product-wrap flex">
      <div>
        <img :src="wine" class="product-item-img">
        <div class="product-name">归庄黄酒</div>
      </div>
      <div>
        <img :src="grape" class="product-item-img">
        <div class="product-name">晴王葡萄</div>
      </div>
      <div>
        <img :src="rice" class="product-item-img">
        <div class="product-name">雅鹿大米</div>
      </div>
      <div>
        <img :src="paper" class="product-item-img">
        <div class="product-name">棉柔巾</div>
      </div>
    </div>
    <img :src="zhibo" class="zhibo-img">
    <img :src="btn" class="btn-img" @click="btnClick">
  </div>
</template>

<script>
import {uwStatisticAction} from "../../utils/util";

export default {
  data() {
    return {
      bgTop: require('@/assets/img/qbg/bg-top.png'),
      ckhw: require('@/assets/img/qbg/ckhw.png'),
      cloud: require('@/assets/img/qbg/cloud.png'),
      productBg: require('@/assets/img/qbg/productBg.png'),
      grape: require('@/assets/img/qbg/grape.png'),
      wine: require('@/assets/img/qbg/wine.png'),
      rice: require('@/assets/img/qbg/rice.png'),
      paper: require('@/assets/img/qbg/paper.png'),
      zhibo: require('@/assets/img/qbg/zhibo.png'),
      btn: require('@/assets/img/qbg/btn.png'),
    }
  },
  mounted() {
    uwStatisticAction('/qbg','村口好物')
  },
  methods:{
    btnClick() {
      window.location.href = 'https://qbg.kq300061.com/'
    }
  }
}
</script>

<style lang="less" scoped>
.qbg{
  width: 100%;
  height: 100vh;
  min-height: 14rem;
  background: url('../../assets/img/qbg/bg.png');
  background-size: 100% 100%;
  position: relative;
  text-align: center;
  .flex{
    display: flex;
  }
  .bg-top{
    width: 100%;
  }
  .ckhw-img{
    width: 2.54rem;
    height: 3.35rem;
    position: absolute;
    top: 0.24rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .cloud-img{
    width: 1.12rem;
    height: .54rem;
  }
  .cloud-r{
    transform: scaleX(-1);
  }
  .cloud-wrap{
    color: #BF9D5F;
    font-size: 0.3rem;
    margin-top: 0.25rem;
    align-items: center;
    justify-content: center;
    span{
      margin: 0 0.15rem;
    }
  }
  .product-wrap{
    margin: 0.22rem 0 0.28rem;
    width: 7.2rem;
    height: 3.18rem;
    background: url(/img/productBg.c15d3896.png);
    background-size: 100% 100%;
    padding: 0.38rem 0;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    .product-item-img{
      width: 1.55rem;
      height: 1.94rem;
      margin: 0 0.058rem
    }
    .product-name{
      color: #FFF4E3;
      font-size: 0.24rem;
      margin-top: 0.05rem;
    }
  }
  .zhibo-img{
    width: 7.17rem;
    height: 3.99rem;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .btn-img{
    width: 2.92rem;
    height: 0.75rem;
    margin: 0.3rem 0 0.36rem;
    margin-left: 50%;
    transform: translateX(-50%);
    display: block;
  }
}
</style>