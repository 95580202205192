import getWXSign from "./wxSign";
import vueMain from "../main";
export var getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim()
      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
      }
  }
  return '';
}
import common from "./common";
import wx from "weixin-js-sdk";
export var appAction = (code,param) => {

    let dict = null
    if (code){
        dict = JSON.stringify({opt: code})
    }
    if (param){
        let p = {}
        p.opt = code
        p.data = param
        dict = JSON.stringify(p)
    }
    console.log("传给app的参数：",dict)
    if (common.getItem('jumpFrom') != undefined && common.getItem('jumpFrom')==='android') {
        VDS.nativeCallback(dict)

    }else if (common.getItem('jumpFrom') != undefined && common.getItem('jumpFrom')==='ios'){
        window.webkit.messageHandlers.JScallOCClick.postMessage(dict);
    }

}
export var uwStatisticAction=(pageViewcontentUrl,pageStr) =>{
    let from = common.getItem('jumpFrom')?common.getItem('jumpFrom'):'h5'
    vueMain.$uweb.trackPageview(pageViewcontentUrl,pageStr)
    vueMain.$uweb.setCustomVar(pageStr+'页','from:'+from + '角色：'+common.getItem('roleName')+'用户名：'+common.getItem('userName')+'手机号：'+common.getItem('phoneNo'))
}
export var shareAction=(title,desc,imgUrl)=>{
    getWXSign().then(()=>{
        wx.updateAppMessageShareData({
            title: title, //
            desc: desc, // 分享描述
            link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })
        wx.updateTimelineShareData({
            title: title, //
            desc: desc, // 分享描述
            link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:imgUrl, // 分享图标
            success: function () {
                // 设置成功
            }
        })

    })
}